import React from 'react';
import Sky from 'react-sky';

export default function Background(){
    return(
        <div >

<Sky
images={{
  0: "https://image.flaticon.com/icons/svg/759/759823.svg",  /* You can pass as many images as you want */
  1: "https://image.flaticon.com/icons/svg/1336/1336934.svg",
   2: "https://www.flaticon.com/premium-icon/icons/svg/2221/2221103.svg", /* you can pass images in any form: link, imported via webpack... */
   3: "https://image.flaticon.com/icons/svg/644/644512.svg",/*your other image... */
}}
how={50} /* Pass the number of images Sky will render chosing randomly */
time={40} /* time of animation */
size={'30px'} /* size of the rendered images */
background={'black'} /* color of background */
/>
        </div>
    )
}