import * as React from "react";
import { Row } from "antd";
import { Timeline, TimelineItem } from "vertical-timeline-component-for-react";

const Experience = () => {
  return (
    <div>
      <div id="exp">
        <Row
          type="flex"
          style={{ alignItems: "center" }}
          justify="center"
          className="expDiv"
        >
          {/* <Col align="" span={24}><h3>Hi, Nice to meet you.</h3>
      <p></p></Col> */}

          <Timeline lineColor={"#000"}>
            <TimelineItem
              key="001"
              dateText="Dec 2021 - Present"
              style={{ color: "#e86971" }}
              // dateInnerStyle={{ background: '#61b8ff', color: '#000' }}
            >
              <h3>Software Deployment Engineer III</h3>
              {/* eslint-disable-next-line */}
              <h4>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://walmart.com/"
                >
                  Walmart, Seattle{" "}
                </a>
              </h4>
              <p>Building applications for DDI - IPAM team.</p>
            </TimelineItem>

            <TimelineItem
              key="002"
              dateText="August 2020 – Dec 2021"
              style={{ color: "#e86971" }}
              // dateInnerStyle={{ background: '#61b8ff', color: '#000' }}
            >
              <h3>Software Deployment Engineer I</h3>
              {/* eslint-disable-next-line */}
              <h4>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://lawyer.com/"
                >
                  Lawyer.com, Basking Ridge{" "}
                </a>
              </h4>
              <p>Working on developing an end-to-end commercial product that deals with all activities within a Lawyer firm</p>
            </TimelineItem>

            <TimelineItem
              key="003"
              dateText="July 2020 – Dec 2020"
              style={{ color: "#e86971" }}
              // dateInnerStyle={{ background: '#61b8ff', color: '#000' }}
            >
              <h3>Research and Development Engineer</h3>
              {/* eslint-disable-next-line */}
              <h4>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.cs.usc.edu/"
                >
                  University of Southern California, Los Angeles{" "}
                </a>
              </h4>
              <p>
                Developing a cloud-based framework with a web interface to study
                changes over the development history of software sys- tems to
                understand when, how, and to what extent individual developers
                influence software quality attributes.
              </p>
            </TimelineItem>
            <TimelineItem
              key="004"
              dateText="June 2019 – May 2020"
              style={{ color: "#e86971" }}
              // dateInnerStyle={{ background: '#61b8ff', color: '#000' }}
            >
              <h3>Research Assistant</h3>
              {/* eslint-disable-next-line */}
              <h4>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="http://calstatela.edu/"
                >
                  California State Univerisity, Los Angeles{" "}
                </a>
              </h4>
              <p>
                Developed a real-time analytic Environmental Dashboard to reduce
                the carbon footprint of the University campus.
              </p>
            </TimelineItem>

            <TimelineItem
              key="005"
              dateText="June 2019 – May 2020"
              style={{ color: "#e86971" }}

              // dateInnerStyle={{ background: '#61b8ff', color: '#000' }}
            >
              <h3>Software Engineer</h3>
              <h4>
                {/* eslint-disable-next-line */}
                <a target="_blank" href="http://calstatelausu.org/">
                  Student Union, California State Univerisity, Los Angeles{" "}
                </a>
              </h4>
              <ul>
                <li>Developing and designing RESTful services and APIs.</li>
                <li>Developing front end website architecture.</li>
                <li>Designing user interactions on web pages.</li>
                <li>Developing back end website applications.</li>
              </ul>
            </TimelineItem>

            <TimelineItem
              key="006"
              dateText="Dec 2016 – June 2018"
              style={{ color: "#e86971" }}
              // dateInnerStyle={{ background: '#61b8ff', color: '#000' }}
            >
              <h3>Software Engineer</h3>
              <h4>
                {/* eslint-disable-next-line */}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.linkedin.com/company/cerner-corporation/"
                >
                  {" "}
                  Cerner Corporation, Bengaluru{" "}
                </a>
              </h4>
              <ul>
                <li>
                  Worked on Cerner Millennium’s PowerChart in optimizing its
                  performance and improving its durability.
                </li>
                {/* <li>Full lifecycle application development</li> */}
                <li>
                  Design, code and debug applications in various software
                  languages
                </li>
                <li>
                  Software analysis, code analysis, requirements analysis,
                  software review, identification of code metrics, system risk
                  analysis, software reliability analysis
                </li>
              </ul>
            </TimelineItem>
          </Timeline>
        </Row>
      </div>
    </div>
  );
};
export default Experience;
