import React from 'react';
import './App.css';
import 'antd/dist/antd.css'
import Name from './Components/Name/name'
import Background from './Components/Background/background'
import {MenuBar} from './Components/Menu/menubar'
import About from './Components/About/about'
import MyFooter from './Components/Footer/footer'
import Experience from './Components/Experience/experience';


class App extends React.Component {
  // constructor(){
  //   super();
  //   // document.title="ChandraMouli R";
  // }
  
  render() {
    return (
      <div>
        <Background/>
        <MenuBar/>
        <Name/>
        <About/>
        <Experience/>
        <MyFooter/>
      </div>


    );
  }
}
export default App;
