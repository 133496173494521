import { Layout, Menu } from 'antd';
import React from 'react';

// import React, { useRef, useEffect, useState } from 'react';
import { Link } from "react-scroll";
import Scrollspy from 'react-scrollspy'
const { Header } = Layout;


export const MenuBar =()=>{
  // const [currentMenuElement, setCurrentMenuElement] = useState('1');

 
  // const selectMenu = param => event => {
  //   console.log(param); // your parameter
  //   // console.log(event.type); // event type, e.g.: click, etc.
  //   setCurrentMenuElement(param)
  // };
    return(
        <div>
 <Layout className="layout" style={{position:'fixeds'}}>
    <Header style={{ position: 'fixed', zIndex: 100, width: '100%' }}>
      <Menu theme="dark" style={{float: 'right',width:'300px'}} mode="horizontal" >

{/*       
      <Menu.Item key="1"><Link
    to="home"
    spy={true}
    smooth={true}
    offset={-70}
    duration= {500}
    onClick={(e) => selectMenu('1')(e)}
></Link>Home</Menu.Item>

<Menu.Item key="2" >About <Link
    to="about"
    spy={true}
    smooth={true}
    offset={-70}
    duration= {500}
    onClick={(e) => selectMenu('2')(e)}
></Link></Menu.Item>
       
        <Menu.Item key="3" >Experience <Link
    to="exp"
    spy={true}
    smooth={true}
    offset={-70}
    duration= {500}
    onClick={(e) => selectMenu('3')(e)}
></Link></Menu.Item> */}

<Scrollspy items={ ['home', 'about', 'exp'] } className="myMenu" currentClassName="is-current">
    <li><Link
    to="home"
    spy={true}
    smooth={true}
    // offset={-70}
    duration= {500}
>Home</Link></li>
    <li> <Link
    to="about"
    spy={true}
    smooth={true}
    // offset={-70}
    duration= {500}
>About</Link></li>
    <li><Link
    to="exp"
    spy={true}
    smooth={true}
    // offset={-70}
    duration= {500}
>Experience</Link></li>

    {/* <li><a href="#home">about</a></li> */}

  </Scrollspy>


      </Menu>
     
    </Header>
    
    </Layout>
        </div>
    )
}