import React from 'react';
import Texty from 'rc-texty';
import TweenOne from 'rc-tween-one';
import {
  BrowserView,
  MobileView,
} from "react-device-detect";

export default class Name extends React.Component
{
    constructor(props) {
        super(props);
        this.moment = null;
        this.state = { height: 900 };
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
        this.animation = [
          // { position: 'relative' },
          { left: '-50%' },
          { left: '25%' },
          // { top: '60px' },
          { scale: 0.2 },
          { scale: 1 },
          // { top: 0 },
          // { left: '230px' }
          { left: '-40px' },
          {width:'350px'},
          {borderBottom:'2px solid red',borderRadius:'0px'},
          {backgroundColor:'transparent'},
          // {backgroundColor:'gray'},
    
        ];
      }
      updateWindowDimensions() {
        this.setState({ height: window.innerHeight });
      }
      componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
      }
      
      componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
      }
      Welcome(){
      }
render(){   
    return (
      <div>
        <BrowserView>
        <div id="home" style={{height:this.state.height+'px'}}>
      {/* <div id="home" style={{height:'1000px'}}> */}

        
      
          <div className="centered">
          <Texty className="name ">ChandraMouli R</Texty><br></br>
          <TweenOne
        animation={this.animation}
        paused={this.props.paused}
        // repeat={-1}
        yoyo 
        // style={{ transform: 'scale(1)' }}
        className="code-box-shape"
        />
      
     
      <Texty className='tagline'
          type={'right'}
          mode={'random'}
        >{'Not your average Software Engineer'}</Texty>
  </div>
  </div>
  </BrowserView>

  <MobileView>
  {/* <div id="home" style={{height:this.state.height+'px'}}> */}
      <div id="home" style={{height:'630px'}}>

        
      
          <div className="centered">
          <Texty className="name ">ChandraMouli R</Texty><br></br>
          <TweenOne
        animation={this.animation}
        paused={this.props.paused}
        // repeat={-1}
        yoyo 
        // style={{ transform: 'scale(1)' }}
        className="code-box-shape"
        />
      
     
      <Texty className='tagline'
          type={'right'}
          mode={'random'}
        >{'Not your average Software Engineer'}</Texty>
  </div>
  </div>
  </MobileView>
  </div>
    );
}
}