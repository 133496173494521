import {useState,useEffect} from 'react';
import * as React from 'react';
import {Row,Col,Card} from 'antd'
import axios from 'axios';
import CountUp from 'react-countup';

const { Meta } = Card;
const About = () => {
    const [reposCount, setReposCount] = useState(0);

    const getReposCount=()=>{
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        // dev code
        console.log("Development")
    } else {
        // production code
              axios.get('https://api.github.com/users/zcam007/repos')
  .then((response) => {
    setReposCount(response.data.length);
  });
    }
    
  
    }
    const getContributionsCount=()=>{
        // axios.get('https://api.github.com/users/zcam007/repos')
        // .then((res) => {
        //     const results = res.data.map(obj => {
        //         const robj = {};
        //         robj['name'] = obj.name;
        //         return robj;
        //       });
        //       console.log(results)
        // });
    }

    useEffect(() => {
        getReposCount()
        getContributionsCount();
    });
    return (
        <div id="about">

<Row  type="flex"
      style={{ alignItems: 'center' }}
      justify="center"
      className="description"
     >
      <Col align="middle" span={24}><h3>Hi, Nice to meet you.</h3>
      <p>With a passion for coding, designing, I have both the skill set and professional background necessary to dive deep into the software world. As an upbeat, self-motivated team player with excellent communication, I envision an exciting future in the industry. Browse my site to see all that I have to offer.
</p></Col>
    </Row>



            <div style={{}}>
                <Row  justify="center" type="flex" align="middle">
                    <Col >
                    <Card
    hoverable
    // style={{ width: 240 }}
    cover={<img alt="Chandu" style={{width:200}} src="https://raw.githubusercontent.com/zcam007/zcam007.github.io/master/img/me.jpg" />}>
      {/* eslint-disable-next-line */}
   <a target="_blank" href="mailto:r.chandramouli@hotmail.com"> <Meta title="Email me" /></a>
  </Card>
  </Col>     
    <div className="site-card-wrapper site-card-wrapper-mobile " style={{paddingLeft:'5rem',width:700}}>
    <Row gutter={8}>
      <Col span={12}>
       {/* eslint-disable-next-line */}
        <a target="_blank" rel="noopener norefferer" href="https://github.com/zcam007">        <Card title="Public Repos" bordered={false} style={{textAlign:'center'}}>
          {/* {reposCount} */}
          <CountUp end={reposCount} duration={3.75} suffix=""  />
        </Card>
        </a>

      </Col>
      <Col span={12}>
        <Card title="Lines of code" bordered={false} style={{textAlign:'center'}}>
          {/* {10000}+ */}
          <CountUp end={11000} duration={4.75} suffix="+" />

        </Card>
      </Col>
      {/* <Col span={8}>
        <Card title="Card title" bordered={false}>
          Card content
        </Card>
      </Col> */}
    </Row>
  </div>
    
                   </Row>
               

            </div>

            


        </div>
        
    );

    
};

export default About;