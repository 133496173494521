import * as React from 'react';
import Footer from 'rc-footer';
import 'rc-footer/assets/index.css'; // import 'rc-footer/asssets/index.less';
const MyFooter=()=>{
return(
<div>
<Footer
    columns={[
      {
            items:[{
                icon: (
                    <img src="https://www.flaticon.com/premium-icon/icons/svg/3015/3015805.svg" alt="LinkedIn Icon" />
                  ),
                  title: 'LinkedIn',
                  url: 'https://www.linkedin.com/in/r-chandra-mouli/',
                //   description: 'Hello',
                  openExternal: true,
            },
        
        ]
      },
      {
        items:[
    {
        icon: (
            <img src="https://image.flaticon.com/icons/svg/733/733553.svg" alt="GitHub Icon" />
          ),
          title: 'GitHub',
          url: 'https://github.com/zcam007',
        //   description: 'Hello',
          openExternal: true, 
    }
    ]
      },
      {
        items:[
    {
        icon: (
            <img src="https://image.flaticon.com/icons/svg/2111/2111398.svg"J alt="Facebook Icon" />
          ),
          title: 'Facebook',
          url: 'https://facebook.com/zcam007',
        //   description: 'Hello',
          openExternal: true, 
    }
    ]
      },
      {
        items:[
    {
        icon: (
            <img src="https://image.flaticon.com/icons/svg/889/889147.svg" alt="Twitter Icon" />
          ),
          title: 'Twitter',
          url: 'https://twitter.com/zcam007',
        //   description: 'Hello',
          openExternal: true, 
    }
    ]
      },
      
    ]}
    bottom=" Made with ❤ by ChandraMouli"
    
    // columnLayout="space-between"
  />

    </div>)
}

export default MyFooter;